export const habit = [ 
    
    {
        source: './habit/01.jpg'
    },

    {
        source: './habit/02.jpg'
    },

    {
        source: './habit/03.jpg'
    },

    {
        source: './habit/04.jpg'
    },

    {
        source: './habit/05.jpg'
    },

    {
        source: './habit/06.jpg'
    },

    {
        source: './habit/07.jpg'
    },

    {
        source: './habit/08.jpg'
    },

    {
        source: './habit/09.jpg'
    },

    {
        source: './habit/010.jpg'
    },
    

];








