export const flowers = [ 
    
    {
        source: './flowers/01.png'
    },

    {
        source: './flowers/02.png'
    },

    {
        source: './flowers/03.png'
    },

    {
        source: './flowers/04.png'
    },

    {
        source: './flowers/05.png'
    },

    {
        source: './flowers/06.png'
    },

    {
        source: './flowers/07.png'
    },

    {
        source: './flowers/08.png'
    },
    

];